import type { ITransitionData } from '@barba/core';
import gsap from 'gsap';

function blink(el: Element, direction: 'in' | 'out' = 'out', delay = 0): Promise<void> {
    return new Promise((resolve) => {
        const tl = gsap.timeline({
            defaults: { delay },
            onComplete: () => {
                if (direction === 'in') {
                    gsap.set(el, { clearProps: 'opacity' });
                }

                resolve();
            },
        });
        tl.set(el, { transition: 'none' })
            .set(el, { opacity: direction === 'out' ? 0 : 1 })
            .set(el, { opacity: direction === 'out' ? 1 : 0 }, 0.2)
            .set(el, { opacity: direction === 'out' ? 0 : 1 }, 0.4)
            .set(el, { opacity: direction === 'out' ? 1 : 0 }, 0.5)
            .set(el, { opacity: direction === 'out' ? 0 : 1 }, 0.6)
            .set(el, { transition: '' });
    });
}

function fadeIn(el: Element): Promise<void> {
    return new Promise((resolve) => {
        gsap.fromTo(el, { opacity: 0 }, { duration: 0.5, opacity: 1, onComplete: resolve });
    });
}

function fadeOut(el: Element): Promise<void> {
    return new Promise((resolve) => {
        gsap.to(el, { duration: 0.5, opacity: 0, onComplete: resolve });
    });
}

function timeout(ms: number): Promise<void> {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
}

export default {
    leave({ current }: ITransitionData): Promise<void> {
        return new Promise((resolve) => {
            const blinkElements = Array.from(current.container.querySelectorAll('.js-blink'));
            const fadeElements = Array.from(current.container.querySelectorAll('.js-fade'));

            if (blinkElements.length > 0) {
                Promise.all([
                    ...fadeElements.map((el) => fadeOut(el)),
                    ...blinkElements.map((el) => blink(el, 'out', Math.random() * 0.5)),
                ])
                    .then(() => {
                        current.container.style.display = 'none';
                        return timeout(300);
                    })
                    .then(() => resolve());
            } else {
                resolve();
            }
        });
    },

    enter({ next }: ITransitionData): Promise<void> {
        return new Promise((resolve) => {
            const blinkElements = Array.from(next.container.querySelectorAll('.js-blink'));
            const fadeElements = Array.from(next.container.querySelectorAll('.js-fade'));

            gsap.set([...blinkElements, ...fadeElements], { opacity: 0 });
            window.scrollTo({ top: 0, behavior: 'auto' });

            timeout(300).then(() => {
                if (blinkElements.length > 0) {
                    Promise.all([
                        ...fadeElements.map((el) => fadeIn(el)),
                        ...blinkElements.map((el) => blink(el, 'in', Math.random() * 0.5)),
                    ]).then(() => resolve());
                } else {
                    resolve();
                }
            });
        });
    },
};
