const map = new Map<Element, (event: any) => void>();

export default () => {
    const checkboxesContainer = document.querySelectorAll('[data-filters-container]');

    checkboxesContainer.forEach((container) => {
        const checkboxes = container.querySelectorAll<HTMLInputElement>('[data-filter]');
        const choiseAll = container.querySelector<HTMLInputElement>('[data-filter="all"]');

        choiseAll?.addEventListener('click', () => {
            if (choiseAll.checked) {
                checkboxes.forEach((checkbox) => {
                    checkbox.checked = false;
                });
                choiseAll.checked = true;
            }
        });

        const onClick = (e: MouseEvent) => {
            if (e.target && e.target !== choiseAll) {
                if (choiseAll && choiseAll.checked) {
                    choiseAll.checked = false;
                }
            }
        };

        checkboxes.forEach((checkbox) => {
            checkbox.addEventListener('click', onClick);
            map.set(checkbox, onClick);
        });
    });

    return {
        destroy: () => {
            checkboxesContainer.forEach((container) => {
                const checkboxes = Array.from(container.querySelectorAll<HTMLInputElement>('[data-filter]'));
                const choiseAll = container.querySelector<HTMLInputElement>('[data-filter="all"]');

                [...checkboxes, choiseAll].forEach((el) => {
                    if (el) {
                        const fn = map.get(el);

                        if (fn) {
                            el.removeEventListener('click', fn);
                        }
                    }
                });
            });
        },
    };
};
