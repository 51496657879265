import './LitPopup.scss';
import { LitElement } from 'lit';
import LitPopup from 'lit-popup';
import { lockScroll, unlockScroll } from '../lockScroll';
import { getCSSCustomProp } from '../../utils/css';

export interface LitPopupElement {
    instance: LitPopup;
    _leaveTimeout: NodeJS.Timeout;
}

export class LitPopupElement extends LitElement {
    static get properties() {
        return {
            name: {
                type: Boolean,
            },
            opened: {
                type: String,
            },
        };
    }

    createRenderRoot() {
        /**
         * Render template without shadow DOM. Note that shadow DOM features like
         * encapsulated CSS and slots are unavailable.
         */
        return this;
    }

    connectedCallback() {
        super.connectedCallback();
        const name = this.getAttribute('data-lit-popup');
        const leaveDurationInSeconds = getCSSCustomProp(this, '--leave-duration', 'number') as number;

        if (!name) {
            throw new Error('[lit-popup] Name should be provided.');
        }

        this.instance = new LitPopup(name, {
            // onOpen: () => {
            //     clearTimeout(this._leaveTimeout);
            //     this._lockBodyScroll();
            // },
            onOpenComplete: () => {
                const focusableOnOpenElement = this.renderRoot.querySelector<HTMLElement>('[data-focus-on-popup-open]');
                setTimeout(() => focusableOnOpenElement?.focus({ preventScroll: true }), 50);
            },
            // onClose: () => {
            //     this._leaveTimeout = setTimeout(() => {
            //         this._unlockBodyScroll();
            //     }, leaveDurationInSeconds * 1000);
            // },
        });
    }

    attributeChangedCallback(name: string, oldVal: string | null, newVal: string | null) {
        super.attributeChangedCallback(name, oldVal, newVal);

        if (name === 'opened') {
            if (typeof newVal === 'string') {
                this.instance.open();
            } else {
                this.instance.close();
            }
        }
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        clearTimeout(this._leaveTimeout);

        if (this.instance) {
            this.instance.destroy();
        }
    }

    open() {
        this.instance?.open();
    }

    close() {
        this.instance?.close();
    }

    protected _lockBodyScroll() {
        lockScroll();
    }

    protected _unlockBodyScroll() {
        unlockScroll();
    }
}

customElements.define('lit-popup', LitPopupElement);

declare global {
    interface HTMLElementTagNameMap {
        'lit-popup': LitPopupElement;
    }
}
