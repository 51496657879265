let header: HTMLElement | null;
let headerWrapper: HTMLElement | null | undefined;
export function lockScroll() {
    header = document.querySelector<HTMLElement>('.js-header');
    headerWrapper = header?.querySelector<HTMLElement>('.js-header-wrapper');
    const getScrollBarWidth = () => {
        const outer = document.createElement('div');

        outer.style.cssText = `
            position: absolute;
            top: -9999px;
            width: 50px;
            height: 50px;
            overflow: scroll;
            visibility: hidden;
        `;

        document.body.appendChild(outer);
        const scrollbarWidth = outer.offsetWidth - outer.clientWidth;
        document.body.removeChild(outer);

        return scrollbarWidth;
    };

    if (document.body.scrollHeight > window.innerHeight) {
        document.body.style.paddingRight = `${getScrollBarWidth()}px`;
    } else {
        document.body.style.paddingRight = ``;
    }
    if (header) {
        header.style.width = `calc(100% - ${getScrollBarWidth()}px)`;

        if (document.body.scrollHeight === window.innerHeight) {
            header.style.right = `${getScrollBarWidth()}px`;

            if (headerWrapper) {
                headerWrapper.style.marginRight = `-${getScrollBarWidth()}px`;
                headerWrapper.style.width = `calc(100% + ${getScrollBarWidth()}px)`;
            }
        }
    }
    document.body.style.overflow = 'hidden';
}

export function unlockScroll() {
    document.body.style.paddingRight = ``;
    if (header) {
        header.style.paddingRight = ``;
        header.style.right = `0`;
        header.style.width = `100%`;
    }
    if (headerWrapper) {
        headerWrapper.style.marginRight = ``;
        headerWrapper.style.width = ``;
    }
    document.body.style.overflow = '';
}
