import createFormSender from '../../modules/ajax-form-sender';
import createValidator from '../../modules/validator';

const SELECTOR = '.js-ajax-form';
const map = new WeakMap<HTMLFormElement, Record<string, any>>();

const clearAntispamInput = (form: HTMLFormElement) => {
    const checkInput = form.querySelector<HTMLInputElement>('input[name="check_val"]');
    if (checkInput) {
        checkInput.value = '';
    }
};

export default function ajaxForm() {
    const forms = Array.from(document.querySelectorAll<HTMLFormElement>(SELECTOR));

    if (forms.length > 0) {
        forms.forEach((form) => {
            let isSubmitting = false;
            const validator = createValidator(form, {
                messages: {
                    en: {
                        EMPTY_FIELD: 'Must be filled',
                    },
                },
                scrollToInvalidInputOptions: {
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'center',
                },
            });
            const sender = createFormSender(form, {
                shouldClearInputs: true,
                onBeforeSend: () => {
                    clearAntispamInput(form);
                },
                onSuccess: ({ success, message }) => {},
                onError: () => {},
                onComplete: () => {},
            });
            map.set(form, sender);

            form.addEventListener('submit', (event) => {
                if (isSubmitting) return;
                event.preventDefault();
                const isFormValid = validator.validate();
                if (isFormValid) {
                    isSubmitting = true;
                    sender.send().finally(() => {
                        isSubmitting = false;
                    });
                }
            });
        });
    }
}
