import barba from '@barba/core';
import type { LitPopupElement } from './LitPopupElement/LitPopupElement';
import { lockScroll, unlockScroll } from './lockScroll';

const map = new Map<Element, (event: any) => void>();
const header = document.querySelector('.js-header');
let arrPopups: LitPopupElement[] = [];
let closePopup: HTMLElement | null;

barba.hooks.leave(() => {
    unlockScroll();
    setTimeout(() => {
        header?.classList.remove('is-black');

        if (closePopup) {
            closePopup.removeAttribute('data-lit-popup-close');
            closePopup.setAttribute('data-lit-popup-open', 'menu');
            closePopup.classList.remove('is-active');
            closePopup.blur();
        }
    }, 200);
});

export default function togglePopup() {
    const arrPopupTrigger = document.querySelectorAll<HTMLElement>('.js-popup-trigger');
    arrPopups = Array.from(document.querySelectorAll<LitPopupElement>('[data-lit-popup]'));
    closePopup = document.querySelector<HTMLElement>('#closePopup');
    const namePopupCloseBtn: string | null | undefined = closePopup?.getAttribute('data-lit-popup-open');

    function closeAllModal() {
        arrPopups.forEach((popup) => {
            popup.close();
        });
    }

    arrPopupTrigger.forEach((item) => {
        const namePopup = item.getAttribute('data-lit-popup-open');

        const onClick = () => {
            if (item.hasAttribute('data-lit-popup-open') && namePopup && closePopup) {
                closeAllModal();
                lockScroll();
                setTimeout(() => {
                    header?.classList.add('is-black');
                }, 100);
                setTimeout(() => {
                    closePopup.removeAttribute('data-lit-popup-open');
                    closePopup.setAttribute('data-lit-popup-close', namePopup);
                    closePopup.classList.add('is-active');
                    item.blur();
                }, 200);
            } else if (item.hasAttribute('data-lit-popup-close') && namePopup && closePopup) {
                unlockScroll();
                setTimeout(() => {
                    header?.classList.remove('is-black');
                    closePopup.removeAttribute('data-lit-popup-close');
                    if (namePopupCloseBtn) closePopup.setAttribute('data-lit-popup-open', namePopupCloseBtn);
                    closePopup.classList.remove('is-active');
                    closePopup.blur();
                }, 200);
            }
        };

        item.addEventListener('click', onClick);
        map.set(item, onClick);
    });

    return {
        destroy: () => {
            header?.classList.remove('is-black');

            arrPopupTrigger.forEach((item) => {
                const fn = map.get(item);

                if (fn) {
                    item.removeEventListener('click', fn);
                }
            });
        },
    };
}
