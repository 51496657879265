function onInputChange(event: any) {
    if (event.target.value.length >= 1) {
        event.target.classList.remove('is-error');
    } else {
        event.target.classList.remove('is-success');
        event.target.classList.remove('is-error');
    }
}

function onCheckboxChange(event: any) {
    if (event.target.checked) {
        event.target.classList.remove('is-error');
    } else {
        event.target.classList.add('is-error');
    }
}

export default () => {
    const inputsOfForm = document.querySelectorAll<HTMLInputElement>('.input');
    const arrCkecbox = document.querySelectorAll<HTMLInputElement>('.js-chekbox-agree');

    inputsOfForm.forEach((input) => {
        input.addEventListener('change', onInputChange);
    });

    arrCkecbox.forEach((checkbox) => {
        checkbox.addEventListener('change', onCheckboxChange);
    });

    return {
        destroy: () => {
            inputsOfForm.forEach((input) => {
                input.removeEventListener('change', onInputChange);
            });

            arrCkecbox.forEach((checkbox) => {
                checkbox.removeEventListener('change', onCheckboxChange);
            });
        },
    };
};
