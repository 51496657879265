const content: Record<string, Record<string, string>> = {
    loadingText: {
        ru: 'Загружаем модель...',
        en: 'Loading the model...',
    },
    errorText: {
        ru: 'Не удалось загрузить модель',
        en: 'Failed to load the model',
    },
};

export function createTigerScene() {
    const canvasContainer = document.querySelector<HTMLCanvasElement>('.js-tiger-canvas-container');
    const canvas = document.querySelector<HTMLCanvasElement>('.js-tiger-canvas');
    let rAF: number;

    if (!canvas) return { destroy: () => {} };

    const loaderEl = document.createElement('div');
    loaderEl.className = 'tiger-canvas-loader';

    const svgEl = document.createElement('div');
    svgEl.innerHTML = `
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24" xml:space="preserve">
            <path d="M24.5 12C24.5 14.7763 23.5374 17.4666 21.7761 19.6127C20.0149 21.7588 17.564 23.2278 14.8411 23.7694C12.1182 24.311 9.29169 23.8918 6.84324 22.5831C4.39479 21.2743 2.47588 19.1571 1.41345 16.5922C0.351016 14.0273 0.210809 11.1733 1.01672 8.51659C1.82262 5.85987 3.52478 3.56477 5.83316 2.02237C8.14153 0.479956 10.9133 -0.214338 13.6762 0.0577831C16.4391 0.329904 19.0222 1.5516 20.9853 3.51472L19.1075 5.39249C17.5788 3.8638 15.5674 2.91246 13.4159 2.70056C11.2644 2.48866 9.10605 3.02931 7.30851 4.23039C5.51097 5.43147 4.18549 7.21866 3.55793 9.28745C2.93037 11.3562 3.03955 13.5786 3.86687 15.576C4.69419 17.5733 6.18845 19.222 8.09506 20.2411C10.0017 21.2602 12.2027 21.5866 14.323 21.1649C16.4434 20.7431 18.3519 19.5992 19.7233 17.928C21.0948 16.2569 21.8444 14.1619 21.8444 12H24.5Z" fill="#0A0A0A">
            <animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 12 12" to="360 12 12" dur="1s" repeatCount="indefinite"></animateTransform>
            </path>
        </svg>
    `;
    loaderEl.appendChild(svgEl);

    const loaderTextEl = document.createElement('div');
    loaderTextEl.className = 'tiger-canvas-loader__text';
    loaderTextEl.textContent =
        content.loadingText[document.documentElement.lang.toLowerCase()] || content.loadingText.en;
    loaderEl.appendChild(loaderTextEl);

    function setLoaderError() {
        loaderEl.removeChild(svgEl);
        loaderTextEl.textContent =
            content.errorText[document.documentElement.lang.toLowerCase()] || content.errorText.en;
    }

    let dpr = Math.min(window.devicePixelRatio, 2);
    let width = window.innerWidth;
    let height = window.innerHeight;
    let renderer: any;
    let controls: any;
    let onResize: () => void;

    canvasContainer?.appendChild(loaderEl);

    try {
        Promise.all([
            import(/* webpackChunkName: "three" */ 'three'),
            import(/* webpackChunkName: "GLTFLoader" */ 'three/examples/jsm/loaders/GLTFLoader'),
            import(/* webpackChunkName: "DRACOLoader" */ 'three/examples/jsm/loaders/DRACOLoader'),
            import(/* webpackChunkName: "OrbitControls" */ 'three/examples/jsm/controls/OrbitControls'),
        ]).then(
            ([
                { Color, PerspectiveCamera, Scene, sRGBEncoding, WebGLRenderer },
                { GLTFLoader },
                { DRACOLoader },
                { OrbitControls },
            ]) => {
                renderer = new WebGLRenderer({
                    canvas,
                    antialias: dpr <= 2,
                    powerPreference: 'high-performance',
                });
                renderer.outputEncoding = sRGBEncoding;
                renderer.setClearColor(0x0a0a0a);

                const camera = new PerspectiveCamera(36.9, width / height, 1, 300);

                const scene = new Scene();
                scene.background = new Color(0x0a0a0a);

                controls = new OrbitControls(camera, canvas);
                controls.enableDamping = true;
                controls.enableZoom = false;
                controls.enableRotate = false;

                const gltfLoader = new GLTFLoader();
                const dracoLoader = new DRACOLoader();
                dracoLoader.setDecoderPath(`${PUBLIC_PATH}draco/`);
                gltfLoader.setDRACOLoader(dracoLoader);
                gltfLoader.setPath(`${PUBLIC_PATH}gltf/`);

                gltfLoader.load(
                    'tiger.glb',
                    (gltf) => {
                        dracoLoader.dispose();
                        canvasContainer?.removeChild(loaderEl);
                        scene.add(gltf.scene);
                        controls.enableRotate = true;
                    },
                    undefined,
                    setLoaderError,
                );

                onResize = () => {
                    dpr = Math.min(window.devicePixelRatio, 2);
                    width = window.innerWidth;
                    height = window.innerHeight;

                    renderer.setPixelRatio(dpr);
                    renderer.setSize(width, height);

                    camera.aspect = width / height;
                    camera.position.set(0, 0, (height / width) * (height / width > 1 && width < 768 ? 80 : 140));
                    camera.updateProjectionMatrix();
                };

                const render = () => {
                    controls.update();
                    renderer.render(scene, camera);
                };

                const animate = () => {
                    render();
                    rAF = requestAnimationFrame(animate);
                };

                onResize();
                window.addEventListener('resize', onResize);
                rAF = requestAnimationFrame(animate);
            },
        );
    } catch (err) {
        setLoaderError();
    }

    return {
        destroy: () => {
            cancelAnimationFrame(rAF);

            if (onResize) {
                window.removeEventListener('resize', onResize);
            }

            controls?.dispose();
            renderer?.dispose();
        },
    };
}
