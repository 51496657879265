import { lazyload } from '../custom-elements/lazy-load';

export default () => {
    const projectsContainer = document.querySelector<HTMLElement>('.js-project-container');

    if (!projectsContainer) return;

    const filtersToggle = projectsContainer?.querySelectorAll<HTMLInputElement>('.js-project-filter');
    const projects = projectsContainer?.querySelector<HTMLElement>('.js-project-list');
    const filterLoader = projectsContainer?.querySelector<HTMLElement>('.js-filter-loader');
    const loader = projects?.querySelector<HTMLElement>('.js-loader');
    const applyFilters = projectsContainer?.querySelector<HTMLElement>('.js-filter-apply');

    const filtersContainerMobile = document.querySelector<HTMLElement>('.js-filters-container');
    const filtersMobileInput = filtersContainerMobile?.querySelectorAll<HTMLInputElement>('.js-filter-mobile');

    // для запуска на верстке
    const projectsList = document.querySelectorAll<HTMLInputElement>('.js-projects-item');
    filtersToggle.forEach((filter) => {
        filter.checked = false;
    });
    projectsList.forEach((project) => {
        project.classList.add('is-show');
        project.classList.add('is-active');
    });

    const filters: string[] = [];

    const observerProjects = new IntersectionObserver(
        (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    if (projects && loader) {
                        getNewElements(projects);
                    }
                }
            });
        },
        {
            threshold: 0.2,
        },
    );

    if (loader) {
        observerProjects.observe(loader);
    }

    if (window.matchMedia('(max-width: 1024px)').matches) {
        // для бэка
        // applyFilters?.addEventListener('click', addChoisenFilters);

        // для верстки
        // filtersToggle.forEach(filter => filter.addEventListener('click', addChoisenFilters));
        applyFilters?.addEventListener('click', () => {
            removeAllActiveClass('is-active');
            removeAllActiveClass('is-show');
            filtersToggle.forEach((filter) => {
                addChoisenFilters(filter);
            });
            showAllProjects();
            countActiveProjects();
        });
        // ----- для верстки -----

        // filtersMobileInput?.forEach((filter, i) => filter?.addEventListener('click', addChoisenFilters));

        // для верстки
        filtersMobileInput?.forEach((filter, i) =>
            filter?.addEventListener('click', () => {
                showOverlay();
                setTimeout(() => {
                    showAllProjects();
                    countActiveProjects();
                }, 400);
            }),
        );
        // ----- для верстки -----
    }

    if (window.matchMedia('(min-width: 1025px)').matches) {
        // filtersToggle.forEach(filter => filter.addEventListener('click', addChoisenFilters));
        filtersToggle.forEach((filter) =>
            filter.addEventListener('click', () => {
                showOverlay();
                setTimeout(() => {
                    removeAllActiveClass('is-active');
                    removeAllActiveClass('is-show');
                    filtersToggle.forEach((filter) => {
                        console.log(filter, filter.checked)
                        addChoisenFilters(filter);
                    });
                    showAllProjects();
                    countActiveProjects();
                }, 400);
            }),
        );
    }

    let markerFunction = false;

    function addChoisenFilters(input: HTMLInputElement) {
        // для запуска на верстке
        const filterValue = input.value;

        if (input.name === 'technology') {
            // if (!markerFunction) removeAllActiveClass('is-active');
            addActiveClassToProject('technology', 'is-active');
        }
        if (input.name === 'direction') {
            // if (!markerFunction) removeAllActiveClass('is-show');
            addActiveClassToProject('direction', 'is-show');
        }

        function addActiveClassToProject(attribute: string, activeClass: string) {
            projectsList.forEach((project) => {
                if (filterValue === `all`) {
                    if (input.checked) {
                        project.classList.add(activeClass);
                    }
                } else {
                    const tags = project.getAttribute(`data-${attribute}`);
                    const tagOfProjectArray = tags?.split(',');

                    tagOfProjectArray?.forEach((tagOfProject) => {
                        if (tagOfProject === filterValue) {
                            if (input.checked) {
                                project.classList.add(activeClass);
                            }
                        }
                    });
                }
            });
        }

        // когда будет бэк
        // filters = [];
        // filtersToggle.forEach(filter => {
        //     if (filter.checked) {
        //         filters.push(filter.value);
        //     }
        // });

        // if (projects) {
        //     // getOtherElements(projects);
        // }
    }

    function showAllProjects() {
        let countActiveFilterTechnology = 0;
        let countActiveFilterDirection = 0;
        filtersToggle?.forEach((filter, i) => {
            if (filter.checked) {
                if (filter.name === 'direction') {
                    countActiveFilterDirection++;
                } else {
                    countActiveFilterTechnology++;
                }
            }
        });

        if (countActiveFilterTechnology === 0) {
            projectsList.forEach((project) => {
                project.classList.add('is-active');
                markerFunction = false;
            });
        }

        if (countActiveFilterDirection === 0) {
            projectsList.forEach((project) => {
                project.classList.add('is-show');
                markerFunction = false;
            });
        }
    }

    function showOverlay() {
        const overlay = document.querySelector('.js-container-overlay');
        overlay?.classList.add('is-show');
        setTimeout(() => {
            overlay?.classList.remove('is-show');
        }, 400);
    }

    function countActiveProjects() {
        const message = document.querySelector('.js-projects-message');
        let countActiveProjects = 0;
        projectsList.forEach((project) => {
            if (project.classList.contains('is-active') && project.classList.contains('is-show')) {
                countActiveProjects++;
            }
        });

        if (countActiveProjects === 0) {
            message?.classList.add('is-show');
        } else {
            message?.classList.remove('is-show');
        }
    }

    function removeAllActiveClass(activeClass: string) {
        projectsList.forEach((project) => project.classList.remove(activeClass));
        markerFunction = true;
    }

    const getOtherElements = async (inner: HTMLElement) => {
        const urlNewPage = projectsContainer?.getAttribute('data-url');
        if (urlNewPage) {
            filterLoader?.classList.add('is-show');
            const res = await fetch(urlNewPage, {
                method: 'POST',
                body: JSON.stringify(filters),
            })
                .then((response) => response.json())
                .then((data) => {
                    filterLoader?.classList.remove('is-show');
                    inner.innerHTML = data.data.html;

                    if (data.data.pagination) {
                        inner.innerHTML += data.data.pagination;
                        const loader = inner?.querySelector('.js-loader');
                        if (loader) observerProjects.observe(loader);
                    }

                    if (data.data.url) {
                        window.history.pushState(null, '', data.data.url);
                    }

                    lazyload.update();
                });
        }
    };

    const getNewElements = async (inner: HTMLElement) => {
        const loader = inner?.querySelector('.js-loader');
        const urlNewPage = projectsContainer?.getAttribute('data-url');
        if (urlNewPage) {
            loader?.classList.add('is-show');
            const res = await fetch(urlNewPage, {
                method: 'POST',
            })
                .then((response) => response.json())
                .then((data) => {
                    loader?.remove();
                    inner.innerHTML += data.data.html;

                    if (data.data.pagination) {
                        inner.innerHTML += data.data.pagination;
                        const loader = inner?.querySelector('.js-loader');
                        if (loader) observerProjects.observe(loader);
                    }

                    lazyload.update();
                });
        }
    };
};
