import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default (container: HTMLElement | Document = document) => {
    const processVideo = container.querySelector<HTMLVideoElement>('.js-process-sequence');
    const processVideoLoader = container.querySelector<HTMLVideoElement>('.js-process-sequence-loader');
    const sequenceContainer = container.querySelector<HTMLElement>('.js-process');
    let tlReady = false;
    let tl: gsap.core.Timeline;

    function startVideo() {
        if (processVideo && !tlReady) {
            processVideo.play();
            processVideo.pause();
            tl?.fromTo(processVideo, { currentTime: 0.01 }, { currentTime: processVideo.duration });
            tlReady = true;
        }
    }

    function onCanplaythrough() {
        if (processVideoLoader) {
            processVideoLoader.hidden = true;
        }
        if (processVideo) {
            processVideo.removeEventListener('canplaythrough', onCanplaythrough);
            // processVideo.hidden = false;
        }
        startVideo();
    }

    function onLoadedMetadata() {
        if (processVideoLoader) {
            processVideoLoader.hidden = true;
        }
        if (processVideo) {
            processVideo.removeEventListener('loadedmetadata', onLoadedMetadata);
            // processVideo.hidden = false;
        }
        startVideo();
    }

    if (processVideo) {
        tl = gsap.timeline({
            defaults: { duration: 1 },
            scrollTrigger: {
                trigger: sequenceContainer,
                start: 'top top',
                end: 'bottom bottom',
                scrub: true,
            },
        });

        if (processVideo.readyState === 4) {
            startVideo();
        } else {
            // if (processVideo) {
            //     processVideo.hidden = true;
            // }

            if (processVideoLoader) {
                processVideoLoader.hidden = false;
            }

            /* Make sure the video is 'activated' on iOS */
            document.documentElement.addEventListener('touchstart', onLoadedMetadata);
            processVideo.addEventListener('loadedmetadata', onLoadedMetadata);
            processVideo.addEventListener('canplaythrough', onCanplaythrough);
            // startVideo();
        }
    }

    return {
        destroy: () => {
            document.documentElement.removeEventListener('touchstart', onLoadedMetadata);

            if (processVideo) {
                processVideo.removeEventListener('loadedmetadata', onLoadedMetadata);
                processVideo.removeEventListener('canplaythrough', onCanplaythrough);
            }

            tl?.kill();
        },
    };
};
