import './webpack-imports';
import './sentry';
import './polyfills';
import './custom-elements';
import barba from '@barba/core';
import BaseTransition from './transitions/base';
import { initBaseView } from './views/_base';
import lazyView from './views/_lazy';
import IndexPageView from './views/index';
import { LitPopupElement } from './custom-elements/LitPopupElement/LitPopupElement';

document.documentElement.classList.add('js-ready');

document.addEventListener('DOMContentLoaded', () => {
    const menuEl = document.querySelector<LitPopupElement>('.js-menu');
    const contactUsEl = document.querySelector<LitPopupElement>('.js-formContactUs-popup');

    initBaseView();

    barba.hooks.leave(() => {
        menuEl?.close();
        contactUsEl?.close();
    });

    barba.init({
        transitions: [BaseTransition],
        views: [
            IndexPageView,
            // lazyView('index-page', () =>
            //     import(/* webpackChunkName: "index-page", webpackPrefetch: true */ './views/index').then(
            //         (m) => m.default,
            //     ),
            // ),
        ],
        prefetchIgnore: true,
        preventRunning: true,
        // prevent: ({ el }) => el.tagName === 'A' && !!el.closest('#bx-panel'),
        timeout: 5000,
        // requestError: (trigger, action, url, response) => {
        //     // go to a custom 404 page if the user click on a link that return a 404 response status
        //     // const prevUrl = window.history.state.states[window.history.state.states.length - 2]?.url;
        //     if (action === 'click' && response.status === 404) {
        //         barba.go(`${ROOT_PATH}404/`);
        //         // if (prevUrl) {
        //         //     window.addEventListener('popstate', (event) => {
        //         //         event.preventDefault();
        //         //         barba.go(prevUrl);
        //         //     });
        //         // }
        //     }

        //     // prevent Barba from redirecting the user to the requested URL
        //     // this is equivalent to e.preventDefault()
        //     return false;
        // },
        debug: NODE_ENV === 'development',
    });
});
