import type { LitPopupElement } from '../custom-elements/LitPopupElement/LitPopupElement';
import { lockScroll, unlockScroll } from '../custom-elements/lockScroll';

export default () => {
    const arrTriggerPopup = document.querySelectorAll<HTMLElement>('.js-trigger-popup-not-full');
    arrTriggerPopup.forEach((item) =>
        item.addEventListener('click', () => {
            if (item.hasAttribute('data-lit-popup-open')) {
                const popupName = item.getAttribute('data-lit-popup-open');
                const popup = document.querySelector<LitPopupElement>(`[data-lit-popup="${popupName}"]`);
                const popupInner = popup?.querySelector<HTMLElement>(`.lit-popup-container`);

                const topPosition = item.offsetTop + item.clientHeight;

                if (popupInner) {
                    popupInner.style.top = `${topPosition + 10}px`;
                    popupInner.style.height = `calc(100% - ${topPosition + 10}px`;
                }

                lockScroll();

                popup?.addEventListener('close', unlockScroll);
            }
        }),
    );
};
