import barba from '@barba/core';
import { lazyload } from '../custom-elements/lazy-load';
import togglePopup from '../custom-elements/btn-toggle-popup';
import collapse from '../custom-elements/collapse';
import addPopupAttribute from '../inits/add-popup';
import ajaxForm from '../inits/ajax-form';
import filters from '../inits/filters';
import inputsOfForm from '../inits/inputs';
import masks from '../inits/masks';
import popupNotFullPage from '../inits/popup-not-full-page';
import projectFilters from '../inits/project-filters';
import showFiltersOnPage from '../inits/show-filters-on-page';
import team from '../inits/team';
import video from '../inits/video';
import processSequence from '../inits/process-sequence';
import { createTigerScene } from '../components/tiger';
import { unlockScroll } from '../custom-elements/lockScroll';

export function initBaseView() {
    let tigerScene: any;
    let filtersComponent: any;
    let inputsOfFormComponent: any;
    let masksComponent: any;
    let togglePopupComponent: any;
    let processSequenceComponent: any;

    barba.hooks.beforeEnter((data) => {
        collapse();
        addPopupAttribute();
        video();
        tigerScene = createTigerScene();
        ajaxForm();
        filtersComponent = filters();
        inputsOfFormComponent = inputsOfForm();
        masksComponent = masks();
        popupNotFullPage();
        projectFilters();
        showFiltersOnPage();
        team();
        togglePopupComponent = togglePopup();
        processSequenceComponent = processSequence(data?.next.container);
    });

    barba.hooks.enter((data) => {
        if (data && data.current && data.next && data.current.namespace && data.next.namespace) {
            document.body.classList.replace(data.current.namespace, data.next.namespace);
        }

        lazyload.update();
    });

    barba.hooks.afterEnter((data) => {
        //
    });

    barba.hooks.beforeLeave((data) => {
        if (filtersComponent) {
            filtersComponent.destroy();
            filtersComponent = null;
        }

        if (inputsOfFormComponent) {
            inputsOfFormComponent.destroy();
            inputsOfFormComponent = null;
        }

        if (masksComponent) {
            masksComponent.destroy();
            masksComponent = null;
        }

        if (togglePopupComponent) {
            togglePopupComponent.destroy();
            togglePopupComponent = null;
        }
    });

    barba.hooks.afterLeave((data) => {
        unlockScroll();

        if (tigerScene) {
            tigerScene.destroy();
            tigerScene = null;
        }

        if (processSequenceComponent) {
            processSequenceComponent.destroy();
            processSequenceComponent = null;
        }
    });
}
