import LazyLoad from 'vanilla-lazyload';
import { LitPopupElement } from '../custom-elements/LitPopupElement/LitPopupElement';

export default function _video() {
    const videoPopup = document.querySelector<LitPopupElement>('lit-popup.js-video-popup');
    const video = document.querySelector<HTMLVideoElement>('.js-video');

    if (videoPopup) {
        videoPopup.addEventListener('open', () => {
            if (video) {
                if (video.readyState === 4) {
                    video.play();
                } else {
                    LazyLoad.load(video, {
                        callback_loaded: () => {
                            video.play();
                        },
                    });
                }
            }
        });

        videoPopup.addEventListener('close-complete', () => {
            if (video) {
                video.pause();
                video.currentTime = 0;
            }
        });
    }
}
