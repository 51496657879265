import IMask from 'imask';

export default function masks() {
    const phoneInput = document.querySelectorAll<HTMLElement>('[type="tel"]');
    const masksArr: IMask.InputMask<{
        mask: string;
    }>[] = [];
    phoneInput.forEach((phone) => {
        const currencyMask = IMask(phone, {
            mask: '+{7} 000 000 00 00',
        });
        masksArr.push(currencyMask);
    });

    return {
        destroy: () => {
            masksArr.forEach((mask) => {
                mask.destroy();
            });
        },
    };
}
