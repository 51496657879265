import type { LitPopupElement } from '../custom-elements/LitPopupElement/LitPopupElement';

export default () => {
    const openFilters = document.querySelector<HTMLElement>('.js-trigger-popup-not-full');
    const filtersToggle = document.querySelectorAll<HTMLInputElement>('.js-popup-filter');
    const applyFilters = document.querySelector<HTMLElement>('.js-filter-apply');
    const popup = document.querySelector<LitPopupElement>('[data-lit-popup="filters"]');
    const filtersClose = document.querySelectorAll<HTMLElement>('.js-close-filters');

    const filtersContainerMobile = document.querySelector<HTMLElement>('.js-filters-container');
    const filtersMobile = filtersContainerMobile?.querySelectorAll<HTMLElement>('.js-filter-item');
    const filtersMobileInput = filtersContainerMobile?.querySelectorAll<HTMLInputElement>('.js-filter-mobile');

    let activeFilters: number[] = [];

    if (window.matchMedia('(max-width: 1024px)').matches) {
        openFilters?.addEventListener('click', () => {
            openFilters.classList.add('is-active');
            saveOldFilters();
        });

        applyFilters?.addEventListener('click', () => {
            showFiltersOnPage();
            popup?.close();
            activeButtonOfOpenPopup();
        });

        popup?.addEventListener('close-complete', () => {
            openFilters?.classList.remove('is-active');
            activeButtonOfOpenPopup();
        });

        filtersMobileInput?.forEach((filter, i) =>
            filter?.addEventListener('click', () => {
                const filterItem = filter.closest<HTMLElement>('.js-filter-item');
                if (filterItem) filterItem.style.display = 'none';
                filtersToggle[i].checked = false;
                activeButtonOfOpenPopup();
            }),
        );

        filtersClose.forEach((filter) => filter.addEventListener('click', getOldFilters));
    }

    function showFiltersOnPage() {
        if (filtersMobile) {
            filtersToggle.forEach((filter, i) => {
                let filterInput = filtersMobile[i].querySelector<HTMLInputElement>('.js-filter-mobile');
                if (filter.checked) {
                    filtersMobile[i].style.display = 'block';
                    if (filterInput) filterInput.checked = true;
                } else {
                    filtersMobile[i].style.display = 'none';
                    if (filterInput) filterInput.checked = false;
                }
            });
        }
    }

    function activeButtonOfOpenPopup() {
        let countActiveFilters = 0;
        filtersMobileInput?.forEach((input) => {
            if (input.checked) {
                countActiveFilters++;
                openFilters?.classList.add('is-active');
                return;
            }
        });

        if (countActiveFilters === 0) {
            openFilters?.classList.remove('is-active');
        }
    }

    function saveOldFilters() {
        activeFilters = [];
        filtersToggle.forEach((filter, item) => {
            if (filter.checked) {
                activeFilters.push(item);
            }
        });
    }

    function getOldFilters() {
        filtersToggle.forEach((filter) => {
            filter.checked = false;
        });
        activeFilters.forEach((item) => {
            filtersToggle[item].checked = true;
        });
    }
};
