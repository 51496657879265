import { IViewData } from '@barba/core';

export default {
    namespace: 'index-page',

    beforeEnter({ next }: IViewData) {
        const video = next.container.querySelector<HTMLVideoElement>('video.js-main-intro__image-video');
        video?.play();
    },
};
