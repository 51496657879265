export default function () {
    const teamContainer = document.querySelector<HTMLElement>('.js-team-container');

    if (!teamContainer) return;

    const filtersToggle = teamContainer?.querySelectorAll<HTMLInputElement>('.js-team-filters');
    const peopleList = teamContainer?.querySelectorAll<HTMLElement>('[data-post]');
    const applyFilters = teamContainer?.querySelector<HTMLElement>('.js-filter-apply');

    const filtersContainerMobile = teamContainer.querySelector<HTMLElement>('.js-filters-container');
    const filtersMobileInput = filtersContainerMobile?.querySelectorAll<HTMLInputElement>('.js-filter-mobile');

    if (window.matchMedia('(max-width: 1024px)').matches) {
        applyFilters?.addEventListener('click', choiseActivePerson);
        applyFilters?.addEventListener('click', () => {
            addActiveClassAll(filtersToggle);
        });
        filtersMobileInput?.forEach((filter, i) => filter?.addEventListener('click', choiseActivePerson));

        filtersMobileInput?.forEach((filter, i) =>
            filter?.addEventListener('click', () => {
                addActiveClassAll(filtersMobileInput);
            }),
        );
    }

    if (window.matchMedia('(min-width: 1025px)').matches) {
        filtersToggle.forEach((filter) => filter.addEventListener('click', choiseTeamPerson));
        filtersToggle.forEach((filter) =>
            filter.addEventListener('click', () => {
                addActiveClassAll(filtersToggle);
            }),
        );
    }

    let markerFunction = false;

    function choiseTeamPerson(this: HTMLInputElement) {
        if (!markerFunction) removeAllActiveClass();

        const post = this.value;

        peopleList.forEach((person) => {
            const postOfPersonAll = person.dataset.post;
            const postOfPersonArray = postOfPersonAll?.split(',');

            postOfPersonArray?.forEach((postOfPerson) => {
                if (postOfPerson === post) {
                    if (this.checked) {
                        person.classList.add('is-active');
                    } else {
                        choiseActivePerson();
                    }
                }
            });
        });
    }

    function choiseActivePerson() {
        removeAllActiveClass();

        filtersToggle.forEach((filter) => {
            const post = filter.value;

            peopleList.forEach((person) => {
                const postOfPersonAll = person.dataset.post;
                const postOfPersonArray = postOfPersonAll?.split(',');

                postOfPersonArray?.forEach((postOfPerson) => {
                    if (postOfPerson === post) {
                        if (filter.checked) {
                            person.classList.add('is-active');
                        }
                    }
                });
            });
        });
    }

    function removeAllActiveClass() {
        peopleList.forEach((person) => person.classList.remove('is-active'));
        markerFunction = true;
    }

    function addActiveClassAll(filters: NodeListOf<HTMLInputElement>) {
        let countActiveFilter = 0;
        filters?.forEach((filter, i) => {
            if (filter.checked) {
                countActiveFilter++;
            }
        });
        if (countActiveFilter === 0) {
            peopleList.forEach((item) => {
                item.classList.add('is-active');
                markerFunction = false;
            });
        }
    }
}
