export default function() {
    const eventsItems = document.querySelectorAll('.js-collapse-item');
    eventsItems.forEach((item) => {
        const collapse = item.querySelector('.js-collapse-block');
        item.addEventListener('mouseenter', () => {
            collapse?.removeAttribute('collapsed');
        });
        item.addEventListener('mouseleave', () => {
            collapse?.setAttribute('collapsed', '');
        });
    });
};
